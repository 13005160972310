<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid grid-list-xl>
      <incidents-drill-down-sheet
        :show="showDrillDown"
        :items="detailItems"
        @close="showDrillDown = false"
      />
      <v-layout row wrap>
        <v-flex class="d-flex justify-start" lg6 sm6 xs12>
          <v-btn color="info" @click="copyView"> <v-icon left>mdi-share</v-icon> Share View </v-btn>
        </v-flex>
        <v-flex class="d-flex justify-end" lg6 sm6 xs12>
          <incident-dialog-filter
            @update="update"
            @loading="setLoading"
            :projects="defaultUserProjects"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg12 sm12 xs12>
          <participant-activity
            v-model="groupedItems"
            :loading="loading"
            @detailsSelected="detailsSelected($event)"
          />
        </v-flex>
        <v-flex lg6 sm6 xs12>
          <incident-reporters-location-bar-chart-card
            v-model="groupedItems"
            :loading="loading"
            @detailsSelected="detailsSelected($event)"
          />
        </v-flex>
        <v-flex lg6 sm6 xs12>
          <incident-commanders-location-bar-chart-card
            v-model="groupedItems"
            :loading="loading"
            @detailsSelected="detailsSelected($event)"
          />
        </v-flex>
        <v-flex lg6 sm6 xs12>
          <incident-participants-location-bar-chart-card
            v-model="groupedItems"
            :loading="loading"
            @detailsSelected="detailsSelected($event)"
          />
        </v-flex>
        <v-flex lg6 sm6 xs12>
          <incident-participants-team-bar-chart-card
            v-model="groupedItems"
            :loading="loading"
            @detailsSelected="detailsSelected($event)"
          />
        </v-flex>
        <!-- Statistics Ends -->
      </v-layout>
    </v-container>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { groupBy, sumBy, filter } from "lodash"
  import { parseISO } from "date-fns"

  import IncidentCommandersLocationBarChartCard from "@/dashboard/incident/IncidentCommandersLocationBarChartCard.vue"
  import IncidentDialogFilter from "@/dashboard/incident/IncidentDialogFilter.vue"
  import IncidentParticipantsLocationBarChartCard from "@/dashboard/incident/IncidentParticipantsLocationBarChartCard.vue"
  import IncidentParticipantsTeamBarChartCard from "@/dashboard/incident/IncidentParticipantsTeamBarChartCard.vue"
  import IncidentReportersLocationBarChartCard from "@/dashboard/incident/IncidentReportersLocationBarChartCard.vue"
  import IncidentsDrillDownSheet from "@/dashboard/incident/IncidentsDrillDownSheet.vue"
  import StatWidget from "@/components/StatWidget.vue"
  import ParticipantActivity from "./ParticipantActivity.vue"

  import differenceInHours from "date-fns/differenceInHours"

  export default {
    name: "IncidentDashboard",

    components: {
      IncidentCommandersLocationBarChartCard,
      IncidentDialogFilter,
      IncidentParticipantsLocationBarChartCard,
      IncidentParticipantsTeamBarChartCard,
      IncidentReportersLocationBarChartCard,
      IncidentsDrillDownSheet,
      ParticipantActivity,
      StatWidget,
    },

    data() {
      return {
        tab: null,
        loading: "error",
        items: [],
        detailItems: [],
        showDrillDown: false,
      }
    },

    methods: {
      update(data) {
        this.items = filter(data, function (item) {
          return !item.incident_type.exclude_from_metrics
        })
      },
      detailsSelected(event) {
        this.detailItems = event
        this.showDrillDown = true
      },
      setLoading(data) {
        this.loading = data
      },
      copyView: function () {
        let store = this.$store
        this.$copyText(window.location).then(
          function () {
            store.commit(
              "notification_backend/addBeNotification",
              {
                text: "View copied to clipboard.",
              },
              { root: true }
            )
          },
          function () {
            store.commit(
              "notification_backend/addBeNotification",
              {
                text: "Failed to copy view to clipboard.",
                color: "error",
              },
              { root: true }
            )
          }
        )
      },
    },

    computed: {
      ...mapFields("route", ["query.project"]),
      ...mapFields("auth", ["currentUser.projects"]),


    incidentsByYear() {
      return groupBy(this.items, function (item) {
        return parseISO(item.reported_at).getYear()
      })
    },
    incidentsByMonth() {
      // add year info if necessary
      if (Object.keys(this.incidentsByYear).length > 1) {
        return groupBy(this.items, function (item) {
          return parseISO(item.reported_at).toLocaleString("default", {
            month: "short",
            year: "numeric",
          })
        })
      } else {
        return groupBy(this.items, function (item) {
          return parseISO(item.reported_at).toLocaleString("default", {
            month: "short",
          })
        })
      }
    },
    incidentsByQuarter() {
      return groupBy(this.items, function (item) {
        return "Q" + Math.floor(parseISO(item.reported_at).getMonth() + 3) / 3
      })
    },
    groupedItems() {
      return this.incidentsByMonth
    },
    totalIncidents() {
      return this.items.length
    },
    totalIncidentsCost() {
      return sumBy(this.items, "total_cost")
    },
    averageIncidentCost() {
      return this.totalIncidentsCost / this.totalIncidents
    },
    totalResponseHours() {
      return sumBy(this.items, function (item) {
        let endTime = new Date().toISOString()
        if (item.stable_at) {
          endTime = item.stable_at
        }
        return differenceInHours(parseISO(endTime), parseISO(item.reported_at))
      })
    },
      defaultUserProjects: {
        get() {
          let d = null
          if (this.projects) {
            let d = this.projects.filter((v) => v.default === true)
            return d.map((v) => v.project)
          }
          return d
        },
      },
    },
  }
  </script>
