/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import API from "@/api"

const resource = "/participant_roles_activity"

export default {
    get(options) {
        return API.get(`${resource}`, { params: { ...options } })
    }
}
