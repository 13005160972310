import { forEach, each, has } from "lodash"

import router from "@/router"

export default {
  serializeFilters(filters) {
    let flatFilters = {}
    forEach(filters, function (value, key) {
      // handle filter windows
      // check for boolean, KJ.
      if (typeof value === "boolean") {
        flatFilters[key] = value
        return
      }
      if (has(value, "start")) {
        let startKey = `${key}.start`
        let endKey = `${key}.end`
        flatFilters = { ...flatFilters, ...{ [startKey]: value.start, [endKey]: value.end } }
        return
      }
      each(value, function (item) {
        if (has(flatFilters, key)) {
          if (typeof item === "string" || item instanceof String) {
            flatFilters[key].push(item)
          } else {
            flatFilters[key].push(item.name)
          }
        } else {
          if (typeof item === "string" || item instanceof String) {
            flatFilters[key] = [item]
          } else {
            flatFilters[key] = [item.name]
          }
        }
      })
    })
    return flatFilters
  },
  deserializeFilters(query) {
    let filters = {}
    forEach(query, function (value, key) {
      // If the key contains a dot (e.g., "created_at.start" or "created_at.end"), it is processed as a filter window for date ranges.
      let parts = key.split(".")

      if (parts.length > 1) {
        let root = parts[0]
        if (!filters[root]) {
          filters[root] = { start: null, end: null }
        }

        if (key.includes("start")) {
          filters[root]["start"] = value
        }

        if (key.includes("end")) {
          filters[root]["end"] = value
        }
        return
      }
      // If the key is in the list of specific keys (e.g., "status" or "handshake_completed") and the value is a
      // string representation of a boolean ("true" or "false"), it is processed as a boolean filter.
      // or if the value is an array of strings, it is processed as a filter with string values.
      if (["status", "handshake_completed"].includes(key)) {
        if (value === "true" || value === "false") {
          filters[key] = value === "true"
        }
        else {
          if (typeof value === "string" || value instanceof String) {
            if (has(filters, key)) {
              filters[key].push(value)
            } else {
              filters[key] = [value]
            }
          } else {
            each(value, function (item) {
              if (has(filters, key)) {
                filters[key].push(item)
              } else {
                filters[key] = [item]
              }
            })
          }
        }
        return
      }
      // If the value is a string or an instannce of a string, it is processed as a filter with a string value.
      if (typeof value === "string" || value instanceof String) {
        if (has(filters, key)) {
          filters[key].push({ name: value })
        } else {
          filters[key] = [{ name: value }]
        }
      } else {
        // If the value is an array, it is processed as a filter with string values.
        each(value, function (item) {
          if (has(filters, key)) {
            filters[key].push({ name: item })
          } else {
            filters[key] = [{ name: item }]
          }
        })
      }
    })
    return filters
  },
  updateURLFilters(filters) {
    router.replace({ query: this.serializeFilters(filters) })
  },
}
