<template>
  <div>
    <dashboard-card
      :loading="loading"
      type="line"
      :options="chartOptions"
      :series="series"
      title="Total Activity Per Participant"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { groupBy, forEach } from 'lodash';
import DashboardCard from "@/dashboard/DashboardCard.vue";
import ParticipantActivityApi from "./api"; // Update with the correct path

export default {
  name: "TotalActivityCard",

  components: {
    DashboardCard,
  },

  data() {
    return {
      loading: true,
      activityData: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          title: {
            text: "Month-Year",
          },
          type: 'datetime',
          categories: [],
        },
        dataLabels: {
          enabled: false, // Turn off if too many data points
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        yaxis: {
          title: {
            text: "Activity",
          },
        },
        legend: {
          position: "top",
        },
      },
      series: [],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
        try {
            const response = await ParticipantActivityApi.get(); // Make sure this is the right API call
            this.activityData = response.data;
            this.processData();
            this.loading = false;
        } catch (error) {
            console.error('Error fetching activity data:', error);
            this.loading = false;
        }
    },
    updateCategories() {
      const uniqueDates = new Set();
      this.series.forEach(serie => {
          serie.data.forEach(point => uniqueDates.add(point.x));
      });
      this.chartOptions.xaxis.categories = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
    },

    processData() {
        const activitiesByMonth = {};
        // Processing data to organize by YYYY-MM and then by participant ID
        this.activityData.forEach(([participantId, activity, month, year]) => {
            const monthString = `${year}-${String(month).padStart(2, '0')}`; // Formats to "YYYY-MM"
            console.log(participantId, activity, month, year, monthString);
            if (!activitiesByMonth[monthString]) {
                activitiesByMonth[monthString] = {};
            }
            activitiesByMonth[monthString][participantId] = (activitiesByMonth[monthString][participantId] || 0) + activity;
        });

        const series = Object.entries(activitiesByMonth).reduce((acc, [date, participants]) => {
            Object.entries(participants).forEach(([participantId, activity]) => {
                let participantSeries = acc.find(s => s.name === `Participant ${participantId}`);
                if (!participantSeries) {
                    participantSeries = { name: `Participant ${participantId}`, data: [] };
                    acc.push(participantSeries);
                }
                participantSeries.data.push({ x: date, y: activity });
            });
            return acc;
        }, []);

        // Sort each series data by date to ensure correct order
        series.forEach(s => {
            s.data.sort((a, b) => new Date(a.x) - new Date(b.x));
        });

        this.series = series;
        this.updateCategories();
    },
  },
}
</script>

<style scoped>
/* Add styles if necessary */
</style>
